.imgTa2{
    height: 60px;
}

.imgSaint{
    height: 50px;
}

.imgApp{
    height: 80%;
    width: 90%;
}

.icoCaracteristics{
    width: 75px;
}

.header{
    background-color:#666666;
    color: white;
    /*padding-bottom: 35px;*/
}
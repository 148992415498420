.imgTa{
    width: 450px;
}

@media (max-width: 450px) {
    .imgTa{
        width: 100%;
    }
  }

.bodyTa{
    background-color:#E6E6E6;
    color: white;
    /*padding-top: 50px;
    padding-bottom: 50px;*/
}
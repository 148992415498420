.footer{
    background-color:#666666;
    color: white;
    /*padding-top: 50px;
    padding-bottom: 50px;*/
}

.imgTaFooter{
    height: 250px;
    padding-top: 50px;
}

.icoFooter{
    height: 55px;
}

.icoSocialMedia{
    height: 55px;
    margin: 10px;
    padding: 10px;
}


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
  background-color: #666666;
}

@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'), url(./fonts/GothamBook.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamMedium';
  src: local('GothamMedium'), url(./fonts/GothamMedium.ttf) format('truetype');
}

:root {
  --bgImage: url("https://app.simplitpos.com/img/Isotipo-SimplitPOS.png"); 
  --bgImage-white: url("https://simplitpos.com/img/SimplitPOS_1White.png");
  --main-bg-color0: #FFFFFF;
  --main-bg-color1: #666666;
  --main-bg-color2: #E6E6E6;
  --main-bg-color3: #FF8602;
  --main-list-color0: #e1dddc;
  --main-list-color1: #e9e9e9;
  --main-hover-color0: #FF8602;
  --main-hover-color1: #777475;
  --main-title-color0: #2d2d2d;
  --main-text-color0: #444242;
  --main-text-color1: #FFFFFF;
  --main-text-color2: #FB8303;
  --main-table-color0: #31B700;
  --main-table-color1: #FF7D2A;
  --main-table-color2: #F50A00;
  --main-table-color4: #29ABE2;
  --main-table-color3: #848181;
  --main-bg-ping: #ED5500;
}